
import * as THREE from 'three';
import Stats from 'three/examples/jsm/libs/stats.module.js';
import { GUI } from 'three/examples/jsm/libs/dat.gui.module';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
// import { UnrealBloomPass } from './UnrealBloomPass_2.js';
import { UnrealBloomPass } from './UnrealBloomPass.js';
// import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';


const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

let group;
let container, stats;
const particlesData = [];
let camera, scene, renderer, composer;
let positions, colors;
let particles;
let pointCloud;
let particlePositions;
let linesMesh;

const maxParticleCount = 1000;
let particleCount = 0;
const r = 800;
const rHalf = r / 2;

const MAX_CUBE_STAGES = 100;
const CUBE_INTERVAL_STEP = 100;
const FINAL_PARTICLE_COUNT = 262;
const CUBE_CANVAS_SIZE = 700;
const CUBE_COLOR = 0x22a4ed; //good one
const CUBE_EDGE_COLOR = CUBE_COLOR;
const CUBE_POINT_COLOR = CUBE_COLOR;
const CUBE_LINE_COLOR = CUBE_COLOR;

const effectController = {
  showDots: true,
  showLines: true,
  minDistance: 160,
  limitConnections: false,
  maxConnections: 20,
  particleCount: 262
};

const bloomParams = {
  exposure: 1.1,
  // exposure: 1,
  bloomStrength: 1,
  // bloomStrength: 1.1,
  bloomThreshold: 0,
  bloomRadius: 0
};
let bloomPass;

let inited = false;

function isElementInViewport (el) {
  if (typeof jQuery === "function" && el instanceof jQuery) {
    el = el[0];
  }

  var rect = el.getBoundingClientRect();

  return (
    Math.trunc(rect.top) >= 0 &&
    Math.trunc(rect.left) >= 0 &&
    Math.floor(rect.bottom) <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
    Math.floor(rect.right) <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
}

const loadCubeParticles = () => {
  const animationTimer = setInterval(() => {
    particleCount = parseInt( particleCountStages[counter] );
    particles.setDrawRange( 0, particleCount );
    counter++;
    if (counter === MAX_CUBE_STAGES) {
      clearInterval(animationTimer);
    }
  }, CUBE_INTERVAL_STEP);
}

$(document).ready(function() {
  container = document.getElementById( 'cube-left' );
  let cubeCanvas = document.querySelector(".cube-container canvas");
  bloomPass = new UnrealBloomPass( new THREE.Vector2( cubeCanvas.clientWidth, cubeCanvas.clientHeight ), 1.5, 0.4, 0.85 );
  bloomPass.threshold = bloomParams.bloomThreshold;
  bloomPass.strength = bloomParams.bloomStrength;
  bloomPass.radius = bloomParams.bloomRadius;
  init();
  resizeCanvasToDisplaySize();
  animate();

  const cubeObserverOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.6
  };

  const cubeObserver = new IntersectionObserver(function(
    entries,
    observer
    ) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (!inited) {
            inited = true;
            loadCubeParticles();
          }
        }
      });
    },
    cubeObserverOptions);
  cubeObserver.observe(cubeCanvas);
});

function initGUI() {

  const gui = new GUI();

  gui.add( effectController, "showDots" ).onChange( function ( value ) {

    pointCloud.visible = value;

  } );
  gui.add( effectController, "showLines" ).onChange( function ( value ) {

    linesMesh.visible = value;

  } );
  gui.add( effectController, "minDistance", 10, 300 );
  gui.add( effectController, "limitConnections" );
  gui.add( effectController, "maxConnections", 0, 30, 1 );
  gui.add( effectController, "particleCount", 0, maxParticleCount, 1 ).onChange( function ( value ) {

    particleCount = parseInt( value );
    particles.setDrawRange( 0, particleCount );

  } );

  gui.add( bloomParams, 'exposure', 0.1, 2 ).onChange( function ( value ) {

    renderer.toneMappingExposure = Math.pow( value, 4.0 );

  } );

  gui.add( bloomParams, 'bloomThreshold', 0.0, 1.0 ).onChange( function ( value ) {

    bloomPass.threshold = Number( value );

  } );

  gui.add( bloomParams, 'bloomStrength', 0.0, 3.0 ).onChange( function ( value ) {

    bloomPass.strength = Number( value );

  } );

  gui.add( bloomParams, 'bloomRadius', 0.0, 1.0 ).step( 0.01 ).onChange( function ( value ) {

    bloomPass.radius = Number( value );

  } );

}

export function init() {

  // initGUI();
  container = document.getElementById( 'cube-left' );

  camera = new THREE.PerspectiveCamera( 45, 1, 1, 4000 );
  camera.position.z = 1750;
  camera.position.y = 700;

  const pointLight = new THREE.PointLight( 0xffffff, 1 );
  camera.add( pointLight );


  const controls = new OrbitControls( camera, container );
  controls.minDistance = 1850;
  controls.maxDistance = 3000;
  controls.enablePan = false;
  controls.enableZoom = false;

  scene = new THREE.Scene();

  group = new THREE.Group();
  scene.add( group );
  scene.add( new THREE.AmbientLight( 0x404040 ) );

  const helper = new THREE.BoxHelper( new THREE.Mesh( new THREE.BoxGeometry( r, r, r ) ) );
  helper.material.color.setHex( CUBE_EDGE_COLOR );
  helper.material.blending = THREE.AdditiveBlending;
  helper.material.transparent = true;
  group.add( helper );

  const segments = maxParticleCount * maxParticleCount;

  positions = new Float32Array( segments * 3 );
  colors = new Float32Array( segments * 3 );

  const pMaterial = new THREE.PointsMaterial( {
    color: CUBE_POINT_COLOR,
    size: 3,
    blending: THREE.AdditiveBlending,
    transparent: true,
    sizeAttenuation: false
  } );

  particles = new THREE.BufferGeometry();
  particlePositions = new Float32Array( maxParticleCount * 3 );

  for ( let i = 0; i < maxParticleCount; i ++ ) {

    const x = Math.random() * r - r / 2;
    const y = Math.random() * r - r / 2;
    const z = Math.random() * r - r / 2;

    particlePositions[ i * 3 ] = x;
    particlePositions[ i * 3 + 1 ] = y;
    particlePositions[ i * 3 + 2 ] = z;

    // add it to the geometry
    particlesData.push( {
      velocity: new THREE.Vector3( - 1 + Math.random() * 2, - 1 + Math.random() * 2, - 1 + Math.random() * 2 ),
      numConnections: 0
    } );

  }

  particles.setDrawRange( 0, particleCount );
  particles.setAttribute( 'position', new THREE.BufferAttribute( particlePositions, 3 ).setUsage( THREE.DynamicDrawUsage ) );

  // create the particle system
  pointCloud = new THREE.Points( particles, pMaterial );
  group.add( pointCloud );

  const geometry = new THREE.BufferGeometry();

  geometry.setAttribute( 'position', new THREE.BufferAttribute( positions, 3 ).setUsage( THREE.DynamicDrawUsage ) );
  geometry.setAttribute( 'color', new THREE.BufferAttribute( colors, 3 ).setUsage( THREE.DynamicDrawUsage ) );

  geometry.computeBoundingSphere();

  geometry.setDrawRange( 0, 0 );

  const material = new THREE.LineBasicMaterial( {
    color: CUBE_LINE_COLOR,
    vertexColors: true,
    blending: THREE.AdditiveBlending,
    transparent: true
  } );

  linesMesh = new THREE.LineSegments( geometry, material );
  group.add( linesMesh );

  var parameters = { minFilter: THREE.LinearFilter, magFilter: THREE.LinearFilter, format: THREE.RGBAFormat, stencilBuffer: false };

  let cubeCanvas = document.querySelector(".cube-container canvas");
  var renderTarget = new THREE.WebGLRenderTarget( cubeCanvas.clientWidth, cubeCanvas.clientHeight, parameters );

  renderer = new THREE.WebGLRenderer( { canvas: cubeCanvas, antialias: true, alpha: true } );
  // renderer = new THREE.WebGLRenderer( { antialias: true, alpha: true, premultipliedAlpha: false } );
  // renderer.setPixelRatio( window.devicePixelRatio );
  renderer.setPixelRatio( 1 );
  renderer.toneMapping = THREE.ReinhardToneMapping;
  renderer.toneMappingExposure = Math.pow( bloomParams.exposure, 4.0 );

  renderer.outputEncoding = THREE.sRGBEncoding;

  renderer.setClearColor( 0x000000, 0 );

  const renderScene = new RenderPass( scene, camera );
  renderScene.autoClear = false;
  composer = new EffectComposer( renderer, renderTarget );

  composer.addPass( renderScene );
  composer.addPass( bloomPass );

  window.addEventListener( 'resize', resizeCanvasToDisplaySize);

}

function resizeCanvasToDisplaySize() {
  const canvas = renderer.domElement;
  const width = canvas.clientWidth;
  const height = canvas.clientHeight;
  if (canvas.width !== width ||canvas.height !== height) {
    // you must pass false here or three.js sadly fights the browser
    renderer.setSize(width, height, false);
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
  }
}

export function animate() {

  let vertexpos = 0;
  let colorpos = 0;
  let numConnected = 0;

  for ( let i = 0; i < particleCount; i ++ )
    particlesData[ i ].numConnections = 0;

  for ( let i = 0; i < particleCount; i ++ ) {

    // get the particle
    const particleData = particlesData[ i ];

    particlePositions[ i * 3 ] += particleData.velocity.x;
    particlePositions[ i * 3 + 1 ] += particleData.velocity.y;
    particlePositions[ i * 3 + 2 ] += particleData.velocity.z;

    if ( particlePositions[ i * 3 + 1 ] < - rHalf || particlePositions[ i * 3 + 1 ] > rHalf )
      particleData.velocity.y = - particleData.velocity.y;

    if ( particlePositions[ i * 3 ] < - rHalf || particlePositions[ i * 3 ] > rHalf )
      particleData.velocity.x = - particleData.velocity.x;

    if ( particlePositions[ i * 3 + 2 ] < - rHalf || particlePositions[ i * 3 + 2 ] > rHalf )
      particleData.velocity.z = - particleData.velocity.z;

    if ( effectController.limitConnections && particleData.numConnections >= effectController.maxConnections )
      continue;

    // Check collision
    for ( let j = i + 1; j < particleCount; j ++ ) {

      const particleDataB = particlesData[ j ];
      if ( effectController.limitConnections && particleDataB.numConnections >= effectController.maxConnections )
        continue;

      const dx = particlePositions[ i * 3 ] - particlePositions[ j * 3 ];
      const dy = particlePositions[ i * 3 + 1 ] - particlePositions[ j * 3 + 1 ];
      const dz = particlePositions[ i * 3 + 2 ] - particlePositions[ j * 3 + 2 ];
      const dist = Math.sqrt( dx * dx + dy * dy + dz * dz );

      if ( dist < effectController.minDistance ) {

        particleData.numConnections ++;
        particleDataB.numConnections ++;

        const alpha = 1.0 - dist / effectController.minDistance;

        positions[ vertexpos ++ ] = particlePositions[ i * 3 ];
        positions[ vertexpos ++ ] = particlePositions[ i * 3 + 1 ];
        positions[ vertexpos ++ ] = particlePositions[ i * 3 + 2 ];

        positions[ vertexpos ++ ] = particlePositions[ j * 3 ];
        positions[ vertexpos ++ ] = particlePositions[ j * 3 + 1 ];
        positions[ vertexpos ++ ] = particlePositions[ j * 3 + 2 ];

        colors[ colorpos ++ ] = alpha;
        colors[ colorpos ++ ] = alpha;
        colors[ colorpos ++ ] = alpha;

        colors[ colorpos ++ ] = alpha;
        colors[ colorpos ++ ] = alpha;
        colors[ colorpos ++ ] = alpha;

        numConnected ++;

      }

    }

  }


  linesMesh.geometry.setDrawRange( 0, numConnected * 2 );
  linesMesh.geometry.attributes.position.needsUpdate = true;
  linesMesh.geometry.attributes.color.needsUpdate = true;

  pointCloud.geometry.attributes.position.needsUpdate = true;

  requestAnimationFrame( animate );

  // stats.update();
  render();

}

function render() {
  // resizeCanvasToDisplaySize();

  const time = Date.now() * 0.001;

  group.rotation.y = time * 0.1;
  group.rotation.z = time * 0.1;

  renderer.clear();
  // renderer.render( scene, camera );
  composer.render(scene, camera);

}

let counter = 0;
const particleCountStages = range(0, FINAL_PARTICLE_COUNT, FINAL_PARTICLE_COUNT / MAX_CUBE_STAGES);
